import { ref } from 'vue';

export default function useBreadCrumbs(data) {
  if (!data) {
    return [];
  }
  const breadcrumbs = ref([]);
  data.forEach((breadcrumb) => {
    const innercrumb = {};
    if (breadcrumb.name) {
      innercrumb.title = breadcrumb.name;
    }
    if (breadcrumb.route_id !== null) {
      innercrumb.route = breadcrumb.route_id;
    }
    breadcrumbs.value.push(innercrumb);
  });

  return breadcrumbs;
}
